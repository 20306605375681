$(function () {
  let tabButtons = $('.solutions-tabs .solutions-slide .nav-link');

  tabButtons.on('click', function (e) {
    tabButtons.each(function (el) {
      if (e.currentTarget.id === $(this)[0].id) {
        $(this).attr('aria-expanded', true);
      } else {
        $(this).removeClass('active');
        $(this).attr('aria-expanded', false);
        $(this).attr('aria-selected', false);
      }
    });
  });

  function slideVisibility() {
    if (window.innerWidth < 992) {
      $('#solutions-tabs .solutions-slider').addClass('swiper');
      $('#solutions-tabs .solutions-slider > div').addClass('swiper-wrapper');
      $('#solutions-post-tabs .solutions-post-slider').addClass('swiper');
      $('#solutions-post-tabs .solutions-post-slider > div').addClass('swiper-wrapper');
    } else {
      $('#solutions-tabs .solutions-slider').removeClass('swiper');
      $('#solutions-tabs .solutions-slider > div').removeClass('swiper-wrapper');
      $('#solutions-post-tabs .solutions-post-slider').removeClass('swiper');
      $('#solutions-post-tabs .solutions-post-slider > div').removeClass('swiper-wrapper');
    }
  }

  slideVisibility();
});
