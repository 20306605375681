import { Swiper, Navigation } from 'swiper';

$(function () {
  const $solutionsSlider = $('.solutions-slider');
  const $solutionPostSlider = $('.solutions-post-slider');
  const $partnersSlider = $('.partners-element');
  const $successCasesSlider = $('.success-cases-element');
  const $testimonySlider = $('.testimony-slider');
  const $projectsSlider = $('.projects-element');

  //TO DO
  if (window.innerWidth < 992) {
    if ($solutionsSlider.length > 0) {
      $solutionsSlider.each(function () {
        const [$el] = $(this);

        new Swiper($el, {
          slidesPerView: 'auto',
          spaceBetween: 20,
          grabCursor: true,
        });
      });
    }
  }

  //TO DO
  if (window.innerWidth < 992) {
    if ($solutionPostSlider.length > 0) {
      $solutionPostSlider.each(function () {
        const [$el] = $(this);

        new Swiper($el, {
          slidesPerView: 'auto',
          spaceBetween: 20,
          grabCursor: true,
        });
      });
    }
  }

  if ($partnersSlider.length > 0) {
    $partnersSlider.each(function () {
      const [swiperEl] = $(this).find('.swiper');

      const [buttonPrev] = $(this).find('.swiper-button-prev');
      const [buttonNext] = $(this).find('.swiper-button-next');

      new Swiper(swiperEl, {
        modules: [Navigation],
        navigation: {
          nextEl: buttonNext,
          prevEl: buttonPrev,
        },
        spaceBetween: 32,
        slidesPerView: 3.5,
        speed: 500,
        grabCursor: true,
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          992: {
            slidesPerView: 3.5,
            spaceBetween: 32,
          },
        },
      });
    });
  }

  if ($successCasesSlider.length > 0) {
    $successCasesSlider.each(function () {
      const [$el] = $(this).find('.success-cases-slider');
      const [buttonPrev] = $(this).find('.success-cases-button-prev');
      const [buttonNext] = $(this).find('.success-cases-button-next');

      new Swiper($el, {
        modules: [Navigation],
        navigation: {
          nextEl: buttonNext,
          prevEl: buttonPrev,
        },
        spaceBetween: 0,
        slidesPerView: 3.3,
        speed: 500,
        centeredSlides: true,
        grabCursor: true,
        slideToClickedSlide: true,
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1.5,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 2.3,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 3.3,
            spaceBetween: 0,
          },
        },
      });
    });
  }

  if ($testimonySlider.length > 0) {
    $testimonySlider.each(function () {
      const [$el] = $(this);
      const [buttonPrev] = $(this).find('.testimony-button-prev');
      const [buttonNext] = $(this).find('.testimony-button-next');

      new Swiper($el, {
        modules: [Navigation],
        navigation: {
          nextEl: buttonNext,
          prevEl: buttonPrev,
        },
        spaceBetween: 0,
        slidesPerView: 1,
        speed: 500,
        grabCursor: true,
        slideToClickedSlide: true,
        loop: true,
      });
    });
  }

  if ($projectsSlider.length > 0) {
    $projectsSlider.each(function () {
      const [$el] = $(this).find('.projects-slider');
      const [buttonPrev] = $(this).find('.projects-button-prev');
      const [buttonNext] = $(this).find('.projects-button-next');

      new Swiper($el, {
        modules: [Navigation],
        navigation: {
          nextEl: buttonNext,
          prevEl: buttonPrev,
        },
        spaceBetween: 0,
        slidesPerView: 2,
        speed: 500,
        grabCursor: true,
        slideToClickedSlide: true,
        autoHeight: true,
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          575: {
            slidesPerView: 1.5,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      });
    });
  }
});
