try {
  window.$ = window.jQuery;

  document.addEventListener('DOMContentLoaded', function () {
    require('./parts/Slider.js');
    require('./parts/Tabs.js');
  });
} catch (e) {
  console.error(e);
}
